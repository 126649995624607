import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NoData, RedText, TableChartWrapper } from './styled';

const TableChart = ({ index, criteria, department }) => {
  const tableData = useSelector((state) => state?.data?.tableData);
  const dashboardData = useSelector((state) => state?.data?.dashboardData);

  const timeLine = useMemo(() => {
    if (!dashboardData) {
      return null;
    }
    const response = dashboardData?.map((element) => {
      return element?.time;
    });
    return response || [];
  }, [dashboardData]);

  const listData = useMemo(() => {
    const dataST = tableData?.map((element) => {
      return {
        ...element,
        hospital_data: JSON.parse(element?.hospital_data)?.map((subElement) => {
          const data = subElement?.data.main[index].result || 0;
          return {
            number: data >= 0 ? data : '',
            result: data > 0 ? 'passed' : '',
          };
        }),
      };
    });
    return dataST;
  }, [tableData, index]);

  // Check if hospitalData has data
  const hasHospitalData = (hospitalData) => {
    return hospitalData?.some(data => data.number !== '');
  };

  const checkWarning = (data) => {
    if (data?.result !== 'passed') {
      return true;
    }
    return false;
  };

  const listTable = [''];
  return (
    <TableChartWrapper>
      <div className='margin-top-2'>
        <div className='title-content'>
          Indicator: <RedText>{criteria?.criteria}</RedText>
        </div>
      </div>
      <div className='space' />
      <div className='header-chart background-color'>
        <div className='criteria'>Hospitals</div>
        {timeLine?.map((element, indexTime) => {
          if (indexTime < 4) return <div />;
          return (
            <div
              className={`quarter ${indexTime === 7 && 'border-right-none'}`}
            >
              {element}
            </div>
          );
        })}
      </div>
      {/* {checkNoDataAll() && <NoData>Nodata</NoData>} */}
      {listTable.map((element, indexBorn) => {
        const dataTable = listData;
        return (
          <div key={String(indexBorn)}>
            {dataTable?.map((hospital, indexSub) => 
              hasHospitalData(hospital.hospital_data) && (
                <div
                  className='header-chart border-top-none'
                  key={String(indexSub)}
                >
                  <div className='criteria padding-left-30'>
                    {hospital.hospital_name}
                  </div>
                  {hospital?.hospital_data?.map(
                    (dataHostPital, indexDataHostpital) => {
                      if (indexDataHostpital < 4) return <div />;
                      return (
                        <div
                          key={String(indexDataHostpital)}
                          className={`quarter ${
                            indexDataHostpital === 7 && 'border-right-none'
                          } ${checkWarning(dataHostPital) && 'warning'}`}
                        >
                          {dataHostPital?.number === 'N/A'
                            ? ''
                            : dataHostPital?.number}
                        </div>
                      );
                    }
                  )}
                </div>
              )
            )}
          </div>
        );
      })}
    </TableChartWrapper>
  );
};

export default TableChart;
